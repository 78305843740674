import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

// images
import testmonial from '../../images/anonymous.jpg'
// import testmonial2 from '../../images/anonymous.jpg'

const sliders = [
    {
        text: 'We were overwhelmed, moving from emergency to emergency with little understanding from our close friends or family.  Tami helped us '
            + 'connect with others in a similar situation and develop a plan for dealing with the situation.  She was a lifesaver!',
        // images: testmonial2,
        // title: 'Parent (#1)',
        subTitle: 'Parent of teenage daughter (age 15)'
    },
    {
        text: 'Tami was a critical part of our team when it came to choosing some next steps for our daughter.  She brought options to the table '
            + 'that we hadn’t even considered, and did the legwork to set up appointments for us. She joined us at meetings and was a valuable set of ears, and a neutral third party who could help us parse out the questions we needed to ask.  Most importantly, she was able to gently pose questions to us to help us gain clarity in our decision-making processes.  She was with us each step of the way and never had any agenda other than helping us arrive at the best choices for our unique situation.',
        // images: testmonial2,
        // title: 'Parent (#2)',
        subTitle: 'Parent of teenage daughter (age 17)'
    },    
    {
        text: 'Tami served as our "spirit guide".  She helped us find the right resources and was available as a calming presence throughout some '
            + 'of our most difficult struggles in this area.',
        // images: testmonial2,
        // title: 'Parent (#3)',
        subTitle: 'Parent of teenage daughter (age 14)'
    }
]
const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
};
const Testmonial = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="testimonialImages">
                            <img src={testmonial} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-6">
                        <div className="testimonialContent">
                            <span>What People Say</span>
                            <h2>Client Testimonial</h2>
                            <Slider className="testmonialSlider" {...settings}>
                                {sliders.map((slider, i) => (
                                    <div key={i} className="slideItem">
                                        <p>{slider.text}</p>
                                        <div className="thumbWrap">
                                            <div className="thumbImg">
                                                <img src={slider.images} alt="" />
                                            </div>
                                            <div className="imgContent">
                                                <h4>{slider.title}</h4>
                                                <span>{slider.subTitle}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testmonial