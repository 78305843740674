import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom'
import 'react-modal-video/scss/modal-video.scss'
import './style.scss'

const About = ({ subTitle, title, videoId, className, signature, paragraphs,images,orderLast, showMore }) => {
    const [video, setVideo] = useState(false)
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className={orderLast ? `${orderLast} col col-md-12`:'col col-md-12'}>
                        <div className="aboutImageWrap">
                            <img src={images} alt="" height="300" width="400"/>
                            {videoId && <i onClick={() => setVideo(true)} className="fa fa-play"></i>}
                            <ModalVideo
                                channel='youtube'
                                isOpen={video}
                                videoId={videoId}
                                onClose={() => () => setVideo(false)} />
                        </div>
                        <div className="aboutContent">
                            {subTitle && <span>{subTitle}</span>}
                            <h2>{title}</h2>
                            {paragraphs.map((paragraph, i) => (
                                <p key={i}>{paragraph.text}</p>
                            ))}
                            {signature && <div className="signature">
                                <img src={signature} width="300em" height="auto" alt="" />
                            </div>}
                            {showMore &&
                            <div className="btnStyle">
                                <Link to='/about'>More on Our Team...</Link>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About