import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import Breadcumb from '../../../components/Breadcumb'
import About from '../../../components/About'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/tami-headshot.png'
import signature from '../../../images/signature.png'
import breadcumb from '../../../images/adult-beach-beautiful-936018.jpg'
import './style.scss'

const aboutText = [
    { text: 'I graduated in 1995 from the University of Washington with my Bachelor degree in Nursing and have spent over 20 years working as an oncology nurse.  '+
            'Most recently, I worked as a nurse navigator and assisted women with breast cancer from diagnosis through survivorship.  '+
            'My role as an oncology navigator was to help with referrals, resources, emotional support and advocacy.  '+
            'During this time, I also had a teenager struggling with their own mental health issues.  ' +
            'It became a second job just to deal with their care. During this journey, I found that I was left with web searches as my primary mental health resource.'+
            'It was difficult to find providers that took our insurance and who had openings.  '+
            'I also had no idea of the many pathways and resources that are available for struggling teens and their families. '+
            'At times, it felt like as parents, were all alone on this journey, trying to piece together some sort of mental health pathway that would care for our teenager and family.  '+
            'It became quite clear that families like ours needed a navigator for mental health care.  '+
            'And that is when Village Parent Support was born.' },
    { text: 'In addition to my professional experience, my parent "resume" includes the experience of raising 4 children in a blended, multiracial family.  '+
            'I have had the experience of being divorced, a single parent and then remarrying and blending households.  '+
            'Our family has experienced the roller coaster of mental health that stems from teens that have experienced mental health issues such as self-harm, '+
            'suicide attempts, substance abuse, bipolar disorder, depression, anxiety, and ADHD.  '+
            'Fortunately, our struggling teen has become an independent, hard-working adult who manages their own mental health care with our support as needed. '+
            'In addition, we are co-parenting a foster child who has high functioning autism and ADHD. '+
            'Some mental health systems that we have used include DBT, hospitalization, residential treatment, intensive outpatient, partial hospitalization, '+
            'equine therapy, outpatient psychiatry, outpatient individual therapy, family therapy and support groups.  '+
            'I also have experience with 504 and IEP plans and advocating with the school systems.  '
    }, 
    { text: 'That being said, your child and family has their own unique history, strengths, challenges and journey.  '+
            'The goal of Village Parent Support is to assist parents to find their mental health village by providing resources and referrals to services '+
            'that are available in King County.  I assist parents throughout the spectrum of mental health care, whether it is just trying to find a '+
            'therapist or psychiatrist or needing help transitioning back home from residential treatment.  '+
            'I also help with IEP and 504 advocacy.  My role is to connect you and your family to the resources that are available in the community that you live in.  '+
            'Having a struggling teen is a journey with many ups and downs, and it is an honor to be there for you throughout your journey.  '+
            'My client load is kept small so that my focus can be very personalized for you and your family.'
    }
 ]


const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'about'}
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Founder's Story"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Tami Welch, RN"
                subTitle="Primary Founder"
                images={about}
                signature={signature}
                orderLast="order-last"
                // videoId="XxVg_s8xAms"
                paragraphs={aboutText}
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage