import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeroSlider from '../../../components/HeroSlider'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import BlogArea from '../../../components/BlogArea'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/tami-headshot.png'

const aboutText = [
    { text: `Village Parent Support was started after our family’s experience of dealing with a struggling teenager. During this journey, ` +
            `we found several guides along our way that helped us navigate the available resources and create a family action plan. Fortunately, ` +
            `we were able to set up healthy patterns for our family. Our teenager is now an adult who is living independently and maintaining close ` +
            `relationships with the family.` },
    { text: `Our goal is to help guide your family through the process of establishing an extended support system and to create a plan that will ` +
            `help your teen reach full independence.`},
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'Providing Village Support',
        subTitle: 'for Families with Struggling Teens',
        text: 'We can help!',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperTwo',
        title: 'There is a Community',
        subTitle: 'Available to Help',
        text: 'You are not alone',
        button: 'Contact us now'
    },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Care Connections',
        content: 'Assisting you with locating therapeutic services for your family such as DBT, individual or family therapy, psychiatry'
    },
    {
        icon: 'flaticon-house',
        title: 'Transition Planning',
        content: 'Working with you to create a plan for changes in school or home environments'
    },
    {
        icon: 'flaticon-parents',
        title: 'Community Support',
        content: 'Connecting families to other families facing similar challenges'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Educational Support',
        content: 'Advocacy for your family with IEP/504 plans and assistance with selection and admission to local schools'
    },
]

const HomePage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
            <About
                className="aboutArea"
                title="Our Team"
                images={about}
                paragraphs={aboutText}
                showMore={true}
            />
            <ServiceArea
                className="ourServiceArea"
                title="How We Can Help You"
                subTitle="Available Services"
                services={services}
            />
            <Testmonial
                className="testmonialArea"
            />
            {/* <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default HomePage