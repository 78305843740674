import React from 'react'
import { NavLink } from 'react-router-dom'
import vpslogo from '../../images/vps-logo.png'
import './style.scss'

const HeaderBottom = props => {
    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">
                        <div className="col-xs-2">
                            <div className="vpslogo">
                                <NavLink to="/">
                                    <img src={vpslogo} height="auto" width="150" alt="" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-xs-8 mx-auto my-auto headertext">
                            <h1><p>Village Parent Support</p></h1>
                        </div>                            
                        <div className={"col-xs-2 responsiveWrapper"}>
                            <ul className="mainMenuWrap">
                                <li><NavLink exact to='/'>Home</NavLink></li>
                                <li><NavLink exact to='/about'>Our Team</NavLink></li>
                                <li><NavLink exact to='/contact'>Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderBottom