import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ScrollToTop from '../../components/ScrollToTop'
import HomePage from '../Pages/HomePage'
import AboutPage from '../Pages/AboutPage'
// import TeamPage from '../Pages/TeamPage'
// import BlogLeftPage from '../Pages/BlogLeftPage'
import ContactPage from '../Pages/ContactPage'

class App extends Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route exact path="/about" component={AboutPage} />
                            {/* <Route exact path="/team" component={TeamPage} /> */}
                            <Route exact path="/contact" component={ContactPage} />
                        </Switch>
                    </ScrollToTop>
                </BrowserRouter>
            </Fragment>
        );
    }}

export default App;
