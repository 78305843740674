import React from 'react'
import { Link } from 'react-router-dom'
import vpslogo from '../../images/vps-logo.png'
import './style.scss'

const footerLinks = [
    {
        title: 'Quick Links', menus: [
            { name: 'Home', route: '#' },
            { name: 'Our Team', route: 'about' },
            // { name: 'Getting Started', route: 'getting-started' },
            { name: 'Contact', route: 'contact' },
        ]
    },
    {
        title: 'Contact Us', menus: [
            { name: 'Village Parent Support' },
            { name: 'Tami Welch RN' },
            { name: 'Navigator & Owner' },
            { name: 'Phone: (425) 243-2578', },
            { name: 'Email: tami@villageparentsupport.com', },
        ]
    },
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={vpslogo} alt="" />
                                </Link>
                                <p>Assisting parents to find their mental health village by providing resources and referrals within the King County Area.</p>
                            </div>
                        </div>
                        {footerLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>© 2019 Village Parent Support, LLC. All rights reserved</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    {/* <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea